<script>
import ApiService from "@services/api.service";

export default {
  name: "Learning",
  data() {
    return {
      title: "Learning",
      table: {
        sortBy: "description",
        fields: [
          { key: "description", class: "text-left" },
          { key: "learningCategoryDesc", class: "text-left", label: "Learning Category" },
          { key: "recordDate", class: "text-right", label: "Publish Date" },
          { key: "actions", class: "text-right" }
        ],
        data: [],
        filter: null
      },
      showModal: false
    };
  },
  computed: {
    rowCount: function () {
      return this.table.data.length;
    },
    editMode: function () {
      return this.news.code != null;
    }
  },
  created() {
    this.loadTable();
  },
  methods: {
    loadTable() {
      this.changeLoader(true);
      ApiService.get("learning")
        .then(resp => {
          this.table.data = resp;
        })
        .finally(() => {
          this.changeLoader(false);
        });
    },
    async deleteLearning(item) {
      if (await this.showConfirm(`Delete Learning ${item.description} ?`)) {
        this.changeLoader(true);
        let qs = {
          code: item.code
        };
        ApiService.delete("learning", qs)
          .then(() => {
            this.showToast("success", "Delete Sucessfull");
            this.loadTable();
          })
          .catch(error => {
            this.showToast("error", error.message);
          })
          .finally(() => {
            this.changeLoader(false);
          });
      }
    }
  }
};
</script>

<template>
  <div>
    <b-card>
      <div id="mount-point"></div>
      <div class="d-flex mb-2">
        <router-link :to="{ name: 'cms-learning-detail', params: { mode: 'create' } }">
          <b-btn variant="outline-primary">Create</b-btn>
        </router-link>
        <div class="ml-auto">
          <b-input v-model="table.filter" placeholder="Filter" class="form-control text-right" />
        </div>
      </div>

      <b-table
        :items="table.data"
        :fields="table.fields"
        :sort-by.sync="table.sortBy"
        :filter="table.filter"
        no-provider-filtering
        empty-text="No data to show"
        striped
        outlined
        hover
        show-empty
      >
        <template #cell(recordDate)="data">
          {{ data.item.recordDate | dateTime }}
        </template>
        <template #cell(actions)="data">
          <div>
            <router-link :to="{ name: 'cms-learning-detail', params: { mode: 'edit', learningCode: data.item.code } }">
              <b-btn class="btn btn-sm btn-wide btn-warning mr-1">Edit</b-btn>
            </router-link>
            <b-btn class="btn btn-sm btn-wide btn-danger" @click.stop="deleteLearning(data.item)">Delete</b-btn>
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>
